<template>
  <v-card id="evt-remun-table">
    <v-card-title>
      <v-text-field
        max-width="500"
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar..."
        dense
        single-line
        hide-details
      ></v-text-field>
      <v-spacer />
      <v-spacer />
      <v-tooltip left color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        <span
          >Aqui você acompanha a confecção dos relatórios. Quando o status estiver "Finalizado", você poderá selecionar
          um ou mais relatórios e baixá-los clicando no botão "Baixar relatórios selecionados"</span
        >
      </v-tooltip>
    </v-card-title>
    <v-data-table
      :loading="loading"
      loading-text="Carregando informações..."
      v-model="selected"
      show-select
      :headers="headers"
      :items="reports"
      :options.sync="pagination"
      :items-per-page-options="pagination.size"
      :search="search"
      :sort-by="'created_at'"
      :sort-desc="true"
      :custom-sort="customSort"
      :must-sort="true"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
    >
      <template slot="no-data"> Não há relatórios relacionados às empresas vinculadas ao seu usuário </template>

      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected && item.status === `PROCESSED`"
          :disabled="!(item.status === `PROCESSED`)"
          @input="select($event)"
        >
        </v-simple-checkbox>
      </template>

      <template v-slot:item.name="{ item }">
        <v-icon left>mdi-file-table-outline</v-icon>
        {{ item.name }}
      </template>

      <template v-slot:item.statusMessage="{ item }">
        <v-chip :color="item.statusColor" dark class="blink">
          {{ item.statusMessage }}
        </v-chip>
      </template>
    </v-data-table>

    <div class="download-btn-container">
      <v-btn
        large
        color="secondary"
        class="download-btn"
        @click="downloadDocumentsInfo"
        :disabled="!(selected.length > 0)"
        :loading="downloading"
      >
        <v-icon left>mdi-download</v-icon>
        Baixar relatórios selecionados
      </v-btn>
    </div>

    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 white--text error">
          <v-icon left class="white--text">mdi-alert</v-icon>
          Algo deu errado
        </v-card-title>
        <br />
        <v-card-text class="text-h6"> Tente novamente mais tarde ou entre em contato com o administrador </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text class="primary lighten-2" @click="errorDialog = false" id="IdBtnClose"> fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import 'moment/locale/pt-br';
import esocialService from '@/services/eSocialService.js';
import gedService from '@/services/gedService.js';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      loading: false,
      search: '',
      error: false,
      errorDialog: false,

      headers: [
        {
          text: 'Relatório',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Data de criação',
          value: 'date',
        },
        {
          text: 'Empresa',
          value: 'nrinscrideempregador',
        },
        {
          text: 'Situação',
          align: 'center',
          value: 'statusMessage',
        },
      ],

      statusDict: {
        PROCESSING: {
          message: 'Aguardando processamento',
          color: '#4d81da', // azul
        },
        PROCESSED: {
          message: 'Finalizado: Arquivo pronto para download',
          color: '#459f48', // verde escuro
        },
        ERROR: {
          message: 'Erro: Não foi possível gerar o arquivo',
          color: '#de2c1f', // vermelho escuro
        },
      },
      reports: [],
      selected: [],
      downloading: false,
    };
  },

  computed: {
    pagination: {
      get: function () {
        return this.paginationFilter;
      },
      set: function (value) {
        this.setPagination(value);
      },
    },
    ...mapGetters({
      paginationFilter: 'esocial/pagination',
    }),
  },

  mounted() {
    this.getAll();
    //   let autoUpdateTable10 = window.setInterval(() => this.getAll(), 10000);
    //   window.setTimeout(() => clearInterval(autoUpdateTable10), 600000);
  },

  created() {
    this.init();
  },

  destroyed() {
    clearInterval(this.autoUpdateTable);
  },

  methods: {
    ...mapActions('esocial', ['setPagination']),
    init() {
      this.$on('refresh', this.open);
    },

    open() {
      this.getAll();
    },

    async getAll() {
      try {
        this.loading = true;
        let response = await esocialService.report.getAll();
        this.error = false;
        this.reports = response.data;

        this.reports.map((r) => {
          r.date = moment(r.startProcess).format('DD/MM/YYYY HH:mm:ss');
          r.statusMessage = this.statusDict[r.status].message;
          r.statusColor = this.statusDict[r.status].color;
        });
        this.loading = false;
      } catch (error) {
        console.log('error');
        this.error = true;
        this.errorDialog = true;
      }
    },

    async downloadDocumentsInfo() {
      try {
        this.downloading = true;
        for (let doc of this.selected) {
          let response = await gedService.files.get(doc.gedId);
          this.downloadDocument(response, doc.name);
        }
        this.downloading = false;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorDialog = true;
        this.downloading = false;
      }
    },

    downloadDocument(responseGed, fileName) {
      const linkSource = 'data:application/octet-stream;base64,' + responseGed.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == 'date') {
          if (!isDesc[0]) {
            return new Date(b['created_at']) - new Date(a['created_at']);
          } else {
            return new Date(a['created_at']) - new Date(b['created_at']);
          }
        } else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
            } else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },
};
</script>

<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.blink {
  -webkit-animation: blink-effect 0.75s linear infinite;
  -moz-animation: blink-effect 0.75s linear infinite;
  -ms-animation: blink-effect 0.75s linear infinite;
  -o-animation: blink-effect 0.75s linear infinite;
  animation: blink-effect 0.75s linear infinite;
}

.download-btn-container {
  display: flex;
  justify-content: center;
}

.download-btn {
  margin: 10px;
}
#evt-remun-table {
  margin-left: -9px;
  max-width: 99%;
}
</style>
